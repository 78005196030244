import { ApiService } from "@/core/shared/services/ApiService";

import type { AxiosProgressEvent, GenericAbortSignal } from "axios";

import type {
  ContentOrder,
  PortfolioContentResponse,
  PortfolioContentType,
  PortfolioContentUpdated,
  PortfolioDraftFormData,
  PortfolioDraftPreviewResponse,
  PortfolioDraftResponse,
  PortfolioHandleAvailabilityResponse,
  PortfolioProgressResponse,
  PortfolioPublished,
} from "@/modules/portfolio/services/PortfolioService.types";
import type { PortfolioUpdated } from "@/modules/portfolio/services/PortfolioService.types";

export default class PortfolioService {
  static get(): Promise<PortfolioDraftResponse> {
    return ApiService.get("/creator/profile-drafts");
  }

  /**
   * Get Portfolio content of the creator
   * @endpoint /creator/profile-contents
   * @httpMethod GET
   * @throws {BadRequestApiServiceError}
   */
  static getContent(): Promise<PortfolioContentResponse> {
    return ApiService.get("/creator/profile-contents");
  }

  static getPreview(): Promise<PortfolioDraftPreviewResponse> {
    return ApiService.get("/creator/profile-drafts/preview");
  }

  static update(
    data: PortfolioDraftFormData
  ): Promise<PortfolioProgressResponse> {
    return ApiService.put("/creator/profile-drafts", data);
  }

  static progress(): Promise<PortfolioProgressResponse> {
    return ApiService.get("/creator/profile-drafts/progress");
  }

  static publish(): Promise<PortfolioPublished> {
    return ApiService.put("/creator/profile-drafts/publish");
  }

  static checkHandle(
    handle: string
  ): Promise<PortfolioHandleAvailabilityResponse> {
    return ApiService.get(
      `/creator/profile-drafts/handle-is-available/${handle}`
    );
  }

  /**
   * Save Portfolio content of the creator
   * @endpoint /creator/profile-contents
   * @httpMethod POST
   * @throws {BadRequestApiServiceError}
   */
  static saveContent(
    data: FormData,
    onUploadProgress?: (event: AxiosProgressEvent) => void,
    signal?: GenericAbortSignal
  ): Promise<PortfolioContentUpdated> {
    return ApiService.post(
      "/creator/profile-contents",
      data,
      undefined,
      undefined,
      onUploadProgress,
      signal
    );
  }

  /**
   * Delete Portfolio content of the creator
   * @endpoint /creator/profile-contents/{content_id}
   * @httpMethod DELETE
   * @throws {BadRequestApiServiceError}
   */
  static delete(content_id: number): Promise<PortfolioUpdated> {
    return ApiService.delete(`/creator/profile-contents/${content_id}`);
  }

  /**
   * Delete all Portfolio content of the creator by type
   * @endpoint /creator/profile-drafts/content-by-type/${type}
   * @httpMethod DELETE
   * @throws {BadRequestApiServiceError}
   */
  static deleteAllContentByType(type: string): Promise<PortfolioUpdated> {
    return ApiService.delete(`/creator/profile-drafts/content-by-type/${type}`);
  }

  /**
   * Order the Portfolio content of the creator
   * @endpoint /creator/profile-contents
   * @httpMethod PUT
   * @throws {BadRequestApiServiceError}
   */
  static order(order: ContentOrder): Promise<PortfolioUpdated> {
    return ApiService.put("/creator/profile-contents", {
      content_order: order,
    });
  }

  /**
   * Enable portfolio
   * @endpoint /creator/profiles/enable
   * @httpMethod PUT
   * @throws {BadRequestApiServiceError}
   */
  static enable(): Promise<PortfolioUpdated> {
    return ApiService.put("/creator/profiles/enable");
  }

  /**
   * Disable portfolio
   * @endpoint /creator/profiles/disable
   * @httpMethod PUT
   * @throws {BadRequestApiServiceError}
   */
  static disable(): Promise<PortfolioUpdated> {
    return ApiService.put("/creator/profiles/disable");
  }

  /**
   * Get portfolio content types
   * @endpoint /creator/profile-contents/types
   * @httpMethod GET
   * @throws {BadRequestApiServiceError}
   */
  static getContentTypes(): Promise<PortfolioContentType[]> {
    return ApiService.get("/creator/profile-contents/types");
  }
}
