import type { ChatNotifications } from "@/store/ChatStore";
import { useChatStore } from "@/store/ChatStore";
import type { FlagSocket } from "@/store/FlagSocketStore";
import { useFlagSocketStore } from "@/store/FlagSocketStore";
import { useWebSocketAuthenticationStore } from "@/store/WebSocketAuthenticationStore";
import type { MessageSocket } from "@/store/WebSocketStore";

import type {
  DsLiveNotificationsStats,
  DsLiveNotificationToast,
} from "@devsalsa/vue-core";
import { useDsLiveNotifierStore, useDsMaintenance } from "@devsalsa/vue-core";

import AccountHandler from "@/core/shared/helpers/Account/AccountHandler";
import {
  type ApiServiceErrorData,
  MaintenanceApiServiceError,
} from "@/core/shared/services/Error/ApiServiceError";

import type { ChatMessageInfo } from "@/services/ChatService.types";

import NotificationRouterLink from "@/modules/notification/helpers/NotificationRouterLink";

import router from "@/router";

const APP_NOTIFICATION = "app_notification";
const CHAT_MESSAGE = "chat_message";
const MAINTENANCE = "maintenance";
const UPGRADING = "upgrading";
const HEARTBEAT = "heartbeat";
const AUTHENTICATED = "auth";
const PONG = "pong";
const USER_UPDATED = "user_updated";
const NEW_CHATS = "new_chats";
const NEW_APP_NOTIFICATIONS = "new_app_notifications";
const FLAG_UPDATED = "flag_updated";
const UPDATE_CHAT_MESSAGES = "update_chat_messages";

export class WebSocketStoreHandler {
  /**
   * Receives message from WebSocket, and store that message depending on type message.
   * @param message
   */
  static handle(message: MessageSocket): void {
    const { setOff } = useDsMaintenance();
    if ([MAINTENANCE, UPGRADING].includes(message.type)) {
      //If the received message type is "maintenance" or "upgrading", set the maintenance state and set the notifier maintenance message.
      throw new MaintenanceApiServiceError(
        message.type,
        message.status,
        message.data as ApiServiceErrorData
      );
    }

    //If the received message type is not "maintenance" or "upgrading", reset the maintenance state and delete the notifier maintenance message.
    if (![MAINTENANCE, UPGRADING].includes(message.type)) {
      setOff();
    }

    //Handle the message depending on the type.
    if (message.type === APP_NOTIFICATION) {
      //If receives type message as "app_notification" will be set this state on DsLiveNotifierStore.
      const toast = message.data as DsLiveNotificationToast;
      const route = NotificationRouterLink.get(toast);
      toast.clickable = true;
      toast.onClick = async () => {
        await router.push(route);
        useDsLiveNotifierStore().deleteToast(toast.id);
      };
      useDsLiveNotifierStore().addToast(toast);
    } else if (message.type === CHAT_MESSAGE) {
      //If receives type message as "chat_message" will be set this state on ChatStore.
      useChatStore().setIncomeMessage(message.data as ChatMessageInfo);
    } else if (message.type === USER_UPDATED) {
      //If receives type message as "user_updated" will be updated global account info.
      AccountHandler.getDebounceInfo();
    } else if (message.type === NEW_CHATS) {
      const data = message.data as ChatNotifications;
      useChatStore().setUnreadChats(data.unread_chats);
      useChatStore().setUnreadChatsMessages(data.chats);
    } else if (message.type === UPDATE_CHAT_MESSAGES) {
      const data = message.data as ChatNotifications;
      useChatStore().updateChatMessage(data.from_sequence_id);
    } else if (message.type === NEW_APP_NOTIFICATIONS) {
      const data = message.data as DsLiveNotificationsStats;
      useDsLiveNotifierStore().setUnread(data.unread_notifications);
    } else if (message.type === FLAG_UPDATED) {
      useFlagSocketStore().update(message.data as FlagSocket);
    } else if (message.type === AUTHENTICATED) {
      useWebSocketAuthenticationStore().setAuthenticated(
        message.status === "ok"
      );
    } else if (![HEARTBEAT, PONG].includes(message.type)) {
      throw new Error(
        `Web socket message NOT handled: ${JSON.stringify(message)}`
      );
    }
  }
}
