import type {
  AccountInfo,
  AccountStats,
  AccountToken,
  AccountTwoFactorAuthenticationCodeSent,
  AccountUpdated,
  ContactResponse,
  Phone,
  PhoneUpdated,
  Preference,
  PreferencesResponse,
  PreferenceUpdated,
  VerificationMethods,
} from "@/core/modules/account/services/AccountService.types";
import { ApiService } from "@/core/shared/services/ApiService";

import type { AxiosProgressEvent, GenericAbortSignal } from "axios";
import type { RouteParamValue } from "vue-router";

import { EmailVerificationType } from "@/modules/settings/enums/EmailVerificationTypeEnum";

/**
 * @class AccountService
 */
export default class AccountService {
  /**
   * Register Creator Account
   * @throws {ApiServiceError}
   * @returns {Promise<AccountToken>}
   * @param data
   */
  static register(data: FormData): Promise<AccountToken> {
    return ApiService.post("/creator/creators", data);
  }

  /**
   * Get Information of Account
   * @throws {ApiServiceError}
   * @return {Promise<AccountInfo>}
   */
  static getInfo(): Promise<AccountInfo> {
    return ApiService.get("/creator/creators");
  }

  /**
   * Get stats
   * @throws {ApiServiceError}
   * @return {Promise<AccountUpdated>}
   */
  static webSocketInitialized(): Promise<AccountUpdated> {
    return ApiService.get("/creator/creators/websocket-initialized");
  }

  /**
   * Get categories
   * @throws {ApiServiceError}
   * @return {Promise<number[]>}
   */
  static getCategories(): Promise<number[]> {
    return ApiService.get("/creator/creators/categories");
  }

  static updateCategories(
    category_1: number,
    category_2: number,
    category_3: number
  ): Promise<AccountUpdated> {
    return ApiService.put("/creator/creators/categories", {
      category_1,
      category_2,
      category_3,
    });
  }

  /**
   * Get stats
   * @throws {ApiServiceError}
   * @return {Promise<AccountStats>}
   */
  static getStats(): Promise<AccountStats> {
    return ApiService.get("/creator/creators/stats");
  }

  /**
   * Login account
   * @param email {string} Email
   * @param password {string} Password
   * @throws {ApiServiceError}
   * @return {Promise<AccountToken>}
   */
  static login(email: string, password: string): Promise<AccountToken> {
    return ApiService.post("/creator/creators/login", {
      email: email,
      password: password,
    });
  }

  /**
   * Two-factor authenticate the account
   * @throws {ApiServiceError}
   * @return {Promise<AccountToken>}
   * @param code
   */
  static authenticateTwoFactor(code: string): Promise<AccountUpdated> {
    return ApiService.post("/creator/login/2fa/verify", {
      code: code,
    });
  }

  /**
   * Resend Email Verification
   * @throws {ApiServiceError}
   * @return {Promise<AccountUpdated>}
   */
  static resendTwoFactorAuthenticationCode(
    type: string,
    phoneId = 0
  ): Promise<AccountTwoFactorAuthenticationCodeSent> {
    return ApiService.post("/creator/login/2fa/send", {
      type: type,
      phone_id: phoneId,
    });
  }

  /**
   * Login with Facebook
   * @throws {ApiServiceError}
   * @return {Promise<AccountToken>}
   * @param data
   */
  static loginWithFacebook(data: FormData): Promise<AccountToken> {
    return ApiService.post("/creator/creators/facebook-connect", data);
  }

  /**
   * Login with Google
   * @throws {ApiServiceError}
   * @return {Promise<AccountToken>}
   * @param data
   */
  static loginWithGoogle(data: FormData): Promise<AccountToken> {
    return ApiService.post("/creator/creators/google-login", data);
  }

  /**
   * Login with Apple
   * @throws {ApiServiceError}
   * @return {Promise<AccountToken>}
   * @param data
   */
  static loginWithApple(data: FormData): Promise<AccountToken> {
    return ApiService.post("/creator/creators/apple-connect", data);
  }

  /**
   * Logout Account
   * @throws {ApiServiceError}
   * @return {Promise<AccountUpdated>}
   */
  static logout(): Promise<AccountUpdated> {
    return ApiService.delete("/creator/creators/logout");
  }

  /**
   * Check Email Verification
   * @param code {string} Code
   * @throws {ApiServiceError}
   * @return {Promise<AccountUpdated>}
   */
  static checkEmailVerification(
    code: string | RouteParamValue[]
  ): Promise<AccountUpdated> {
    return ApiService.put(
      `/creator/creators/emails/email-verification/${code}`
    );
  }

  /**
   * Resend Email Verification
   * @throws {ApiServiceError}
   * @return {Promise<AccountUpdated>}
   */
  static resendEmailVerification(
    type = EmailVerificationType.Signup
  ): Promise<AccountUpdated> {
    return ApiService.post("/creator/creators/email-verification/resend", {
      type: type,
    });
  }

  /**
   * Set Email
   * @param email {String} Email
   * @throws {ApiServiceError}
   * @return {Promise<AccountUpdated>}
   */
  static setEmail(email: string): Promise<AccountUpdated> {
    return ApiService.put("/creator/creators/emails", {
      email: email,
    });
  }

  /**
   * Update creator address
   * @endpoint /creator/update-shipping-address
   * @httpMethod POST
   * @throws {BadRequestApiServiceError}
   */
  static updateMailingAddress(
    address_street: string,
    address_street2: string,
    address_city: string,
    address_state: string,
    address_zipcode: string,
    address_country_code: string
  ): Promise<AccountUpdated> {
    return ApiService.put("/creator/creators/shipping-address", {
      address_street,
      address_street2,
      address_city,
      address_state,
      address_zipcode,
      address_country_code,
    });
  }

  /**
   * Update Email
   * @param email {string} Email
   * @throws {ApiServiceError}
   * @return {Promise<AccountUpdated>}
   */
  static updateEmail(email: string): Promise<AccountUpdated> {
    return ApiService.post("/creator/creators/change-email", {
      email: email,
    });
  }

  /**
   * Request Password Reset
   * @param email {string} Email
   * @throws {ApiServiceError}
   * @return {Promise<AccountUpdated>}
   */
  static requestPasswordReset(email: string): Promise<AccountUpdated> {
    return ApiService.post("/creator/creators/lost-password", {
      email: email,
    });
  }

  /**
   * Reset Password
   * @param code {string} Code
   * @param password {string} Password
   * @throws {ApiServiceError}
   * @return {Promise<AccountUpdated>}
   */
  static resetPassword(
    code: string,
    password: string
  ): Promise<AccountUpdated> {
    return ApiService.put("/creator/creators/lost-password", {
      code: code,
      password: password,
    });
  }

  /**
   * Updated Password
   * @param oldPassword {string} Old password
   * @param newPassword {string} New password
   * @throws {ApiServiceError}
   * @return {Promise<AccountUpdated>}
   */
  static updatePassword(
    oldPassword: string,
    newPassword: string
  ): Promise<AccountUpdated> {
    return ApiService.put("/creator/creators/password", {
      old_password: oldPassword,
      new_password: newPassword,
    });
  }

  /**
   * Set Paypal Email
   * @throws {ApiServiceError}
   * @return {Promise<AccountStats>}
   */
  static setPaypalEmail(paypal_email: string): Promise<AccountStats> {
    return ApiService.put("/creator/creators/paypal-email", { paypal_email });
  }

  static updatePhone(phone: string): Promise<AccountUpdated> {
    return ApiService.put("/creator/creators/phone", {
      phone,
    });
  }

  static updatePersonalInformation(
    data: FormData,
    onUploadProgress?: (event: AxiosProgressEvent) => void,
    signal?: GenericAbortSignal
  ): Promise<AccountUpdated> {
    return ApiService.post(
      "/creator/creators/profiles",
      data,
      undefined,
      undefined,
      onUploadProgress,
      signal
    );
  }

  /**
   * Get all creator preferences
   * @throws {ApiServiceError}
   * @return {Promise<PreferencesResponse>}
   */
  static preferences(): Promise<PreferencesResponse> {
    return ApiService.get("/creator/creators/preferences");
  }

  /**
   * Get creator preference by key
   * @param key {string} Key
   * @throws {ApiServiceError}
   * @return {Promise<Preference>}
   */
  static preference(key: string): Promise<Preference> {
    return ApiService.get(`/creator/creators/preferences/${key}`);
  }

  /**
   * Set creator preference
   * @param key {string} Key
   * @param value {string} Value
   * @throws {ApiServiceError}
   * @return {Promise<PreferenceUpdated>}
   */
  static setPreference(key: string, value: string): Promise<PreferenceUpdated> {
    return ApiService.post(`/creator/creators/preferences/${key}`, {
      value,
    });
  }

  /**
   * Set creator preference
   * @param name {string} Name
   * @param email {string} Email
   * @param message {string} Message
   * @throws {ApiServiceError}
   * @return {Promise<ContactResponse>}
   */
  static contact(
    name: string,
    email: string,
    message: string
  ): Promise<ContactResponse> {
    return ApiService.post(`/common/creator-contact-support`, {
      name,
      email,
      message,
    });
  }

  /**
   * Set preference for show or hide mature campaigns
   * @param hideMatureCampaigns 1-> hide, 0: show
   */
  static setMatureCampaigns(
    hideMatureCampaigns: number
  ): Promise<AccountUpdated> {
    return ApiService.put("/creator/creators/hide-mature-campaigns", {
      hide_mature_campaigns: hideMatureCampaigns,
    });
  }

  /**
   * Set preference for show or hide promo(blast) campaigns
   * @param hideBundleCampaigns
   */
  static setBundleCampaigns(
    hideBundleCampaigns: number
  ): Promise<AccountUpdated> {
    return ApiService.put("/creator/creators/hide-bundle-jobs", {
      hide_bundle_jobs: hideBundleCampaigns,
    });
  }

  /**
   * Delete creator account
   */
  static deleteAccount(): Promise<AccountUpdated> {
    return ApiService.delete("/creator/creators");
  }

  static verificationMethods(): Promise<VerificationMethods> {
    return ApiService.get("/creator/login/verification-methods");
  }

  static twoFactorAuthenticationMethods(): Promise<VerificationMethods> {
    return ApiService.get("/creator/2fa/verification-methods");
  }

  static addPhone(phone: string): Promise<Phone> {
    return ApiService.post("/creator/2fa/phone-numbers/create", {
      phone_number: phone,
    });
  }

  static verifyPhone(phone: string, code: string): Promise<Phone> {
    return ApiService.put("/creator/2fa/phone-numbers/verify", {
      phone_number: phone,
      code: code,
    });
  }

  static deletePhone(phone: string): Promise<PhoneUpdated> {
    return ApiService.delete("/creator/2fa/phone-numbers/delete", {
      phone_number: phone,
    });
  }

  static checkOldEmail(): Promise<PhoneUpdated> {
    return ApiService.get("/creator/creators/check-old-email");
  }

  static setPrimary(phoneId: number): Promise<PhoneUpdated> {
    return ApiService.put("/creator/2fa/phone-numbers/set-primary", {
      phone_id: phoneId,
    });
  }

  static setNewsletterSubscription(
    subscribed: boolean
  ): Promise<AccountUpdated> {
    return ApiService.put("/creator/creators/newsletter/add-subscription", {
      subscribed: subscribed,
    });
  }
}
