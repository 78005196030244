import { toRef } from "vue";

import { storeToRefs } from "pinia";

import {
  type DsIAuthService,
  useDsApiStore,
  useDsConnectivity,
  useDsMaintenance,
} from "@devsalsa/vue-core";

import { ApiErrorHandler } from "@/core/shared/helpers/Error/ApiErrorHandler";
import { ErrorTranslator } from "@/core/shared/helpers/Error/ErrorTranslator.ts";
import {
  type ApiServiceErrorData,
  MaintenanceApiServiceError,
} from "@/core/shared/services/Error/ApiServiceError.ts";

import type {
  AxiosProgressEvent,
  AxiosRequestHeaders,
  AxiosResponse,
  GenericAbortSignal,
  Method,
} from "axios";
import axios, { AxiosError, HttpStatusCode } from "axios";
import type { IAxiosRetryConfig } from "axios-retry";
import axiosRetry from "axios-retry";
import { container } from "tsyringe";

/**
 * This interface describes the response from the API.
 *
 * The data object could vary for each endpoint, explaining the "any" type.
 *
 * @interface ApiResponse
 * @memberOf ApiService
 * @property data {T} Data received from the API
 * @property ts {number} Timestamp for the response
 */
// eslint-disable-next-line
export interface ApiResponse<T = any> {
  data: T;
  ts: number;
}

/**
 * This interface describes the response from the API.
 *
 * The data object could vary for each endpoint, explaining the "any" type.
 *
 * @interface ApiSuccessResponse
 * @memberOf ApiService
 */
// eslint-disable-next-line
export interface ApiSuccessResponse<T = any> extends ApiResponse {}

/**
 * This interface describes the response from the API.
 *
 * The data object could vary for each endpoint, explaining the "any" type.
 *
 * @interface ApiErrorResponse
 * @memberOf ApiService
 * @property code {string} Error code sent by the API
 * @property message {string} Error message sent by the API
 */
// eslint-disable-next-line
export interface ApiErrorResponse<T = any> extends ApiResponse {
  code: string;
  message: string;
}

/**
 * @class ApiService
 */
export class ApiService {
  static readonly POST = "post";
  static readonly GET = "get";
  static readonly PUT = "put";
  static readonly DELETE = "delete";

  /**
   * Fetch data using axios. The API can be in maintenance mode, and/or the connection can be unstable.
   *
   * @param method {string} Method (post, get, put, delete)
   * @param endpoint {string} URL Endpoint
   * @param data {any} Body params
   * @param headers {AxiosRequestHeaders} HTTP Headers
   * @param axiosRetryConfig Define the retry strategy
   * @param onUploadProgress
   * @param signal
   * @return {Promise<ApiSuccessResponse>}
   */
  static async fetchData<ApiResponse>(
    method: Method,
    endpoint: string,
    data?: Record<string, unknown> | FormData,
    headers?: AxiosRequestHeaders,
    axiosRetryConfig?: IAxiosRetryConfig,
    onUploadProgress?: (event: AxiosProgressEvent) => void,
    signal?: GenericAbortSignal
  ): Promise<ApiResponse> {
    const client = axios.create();
    if (axiosRetryConfig) {
      const isOnline = toRef(useDsConnectivity(), "isOnline");
      const { setOnline } = useDsConnectivity();
      const { setOn, setOff } = useDsMaintenance();
      const { maintenance } = storeToRefs(useDsApiStore());
      client.interceptors.response.use(
        (response: AxiosResponse) => {
          if (!isOnline.value) {
            //Mark the connection to the API as working if we have a successful response.
            setOnline();
          }
          if (maintenance.value) {
            //Remove the maintenance mode if we have a successful response.
            setOff();
          }
          return response;
        },
        (error: AxiosError) => {
          if (
            !isOnline.value &&
            error.request.status > 0 &&
            error.request.status !== HttpStatusCode.ServiceUnavailable
          ) {
            //Mark the connection to the API as working if we have a failed response but no network error.
            setOnline();
          } else if (
            error.request.status > 0 &&
            error.request.status === HttpStatusCode.ServiceUnavailable
          ) {
            //Enable the maintenance mode if we have a failed response and the status code is 503.
            setOn(
              ErrorTranslator.translate(
                new MaintenanceApiServiceError(
                  (error.response?.data as ApiServiceErrorData).code,
                  "Maintenance",
                  []
                )
              )
            );
          }
          throw error;
        }
      );

      axiosRetry(client, axiosRetryConfig);
    }
    const authService = container.resolve<DsIAuthService>("AuthService");
    const token = authService.getToken();
    const __headers = { ...headers } as AxiosRequestHeaders;
    if (token) {
      Reflect.set(__headers, "Authorization", `Bearer ${token}`);
    }
    return client
      .request({
        method: method,
        url: import.meta.env.VITE_APP_API_URL + endpoint,
        data: data,
        headers: __headers,
        onUploadProgress: onUploadProgress,
        signal: signal,
      })
      .then((response: AxiosResponse) => {
        return response.data.data;
      })
      .catch((error: AxiosError) => {
        ApiErrorHandler.handle(error);
      });
  }

  /**
   * Get
   * @param endpoint {string} Endpoint
   * @param axiosRetryConfig
   * @param signal
   * @return {Promise<ApiSuccessResponse>}
   */
  static get<ApiResponse>(
    endpoint: string,
    axiosRetryConfig?: IAxiosRetryConfig,
    signal?: GenericAbortSignal
  ): Promise<ApiResponse> {
    return ApiService.fetchData(
      ApiService.GET,
      endpoint,
      undefined,
      {} as AxiosRequestHeaders,
      axiosRetryConfig || this.getRetryStrategy(),
      undefined,
      signal
    );
  }

  /**
   * Post
   * @param endpoint {string} Endpoint
   * @param data {any} Body params
   * @param headers {AxiosRequestHeaders}
   * @param axiosRetryConfig
   * @param onUploadProgress
   * @param signal
   * @return {Promise<ApiSuccessResponse>}
   */
  static post<ApiResponse>(
    endpoint: string,
    data?: Record<string, unknown> | FormData,
    headers?: AxiosRequestHeaders,
    axiosRetryConfig?: IAxiosRetryConfig,
    onUploadProgress?: (event: AxiosProgressEvent) => void,
    signal?: GenericAbortSignal
  ): Promise<ApiResponse> {
    return ApiService.fetchData(
      ApiService.POST,
      endpoint,
      data,
      headers,
      axiosRetryConfig,
      onUploadProgress,
      signal
    );
  }

  /**
   * Put
   * @param endpoint {string} Endpoint
   * @param data {any} Body params
   * @param headers {AxiosRequestHeaders}
   * @param signal
   * @return {Promise<ApiSuccessResponse>}
   */
  static put<ApiResponse>(
    endpoint: string,
    data?: Record<string, unknown> | FormData,
    headers?: AxiosRequestHeaders,
    signal?: GenericAbortSignal
  ): Promise<ApiResponse> {
    return ApiService.fetchData(
      ApiService.PUT,
      endpoint,
      data,
      headers,
      undefined,
      undefined,
      signal
    );
  }

  /**
   * Delete
   * @param endpoint {string} Endpoint
   * @param data
   * @param signal
   * @return {Promise<ApiSuccessResponse>}
   */
  static delete<ApiResponse>(
    endpoint: string,
    data?: Record<string, unknown> | FormData,
    signal?: GenericAbortSignal
  ): Promise<ApiResponse> {
    return ApiService.fetchData(
      ApiService.DELETE,
      endpoint,
      data,
      undefined,
      undefined,
      undefined,
      signal
    );
  }

  /**
   * Default retry strategy for the get method
   */
  static getRetryStrategy(): IAxiosRetryConfig {
    const { setOffline } = useDsConnectivity();
    return {
      retries: 10,
      retryDelay: (retryCount: number): number => {
        //Intervals in seconds
        const intervals = [5, 5, 10, 15, 30, 60, 180, 300, 600, 1200, 1800];
        return intervals[retryCount] * 1000;
      },
      retryCondition: (error): boolean => {
        // Only Network Error
        return (
          error.code === AxiosError.ERR_NETWORK ||
          error.status === HttpStatusCode.ServiceUnavailable
        );
      },
      onRetry: (retryCount) => {
        const { maintenance } = storeToRefs(useDsApiStore());
        //If the API is not in maintenance mode, and we have a retry count but no custom callback, mark the connection to the API as unstable.
        if (!maintenance.value && retryCount > 0) {
          setOffline();
        }
      },
    };
  }
}
