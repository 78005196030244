import { DsFlashNotifier, useDsMaintenance } from "@devsalsa/vue-core";

import useTwoFactorAuthentication from "@/core/shared/composables/TwoFactorAuthentication";
import AccountHandler from "@/core/shared/helpers/Account/AccountHandler";
import { ErrorTranslator } from "@/core/shared/helpers/Error/ErrorTranslator";
import type { ApiServiceError } from "@/core/shared/services/Error/ApiServiceError";
import {
  MaintenanceApiServiceError,
  UnauthorizedApiServiceError,
  UnauthorizedTwoFactorAuthenticationApiServiceError,
} from "@/core/shared/services/Error/ApiServiceError";

import router from "@/router";

export class ErrorHandler {
  static handle(error: unknown): void {
    if (error instanceof UnauthorizedApiServiceError) {
      this.handleUnauthorized(error);
    } else if (
      error instanceof UnauthorizedTwoFactorAuthenticationApiServiceError
    ) {
      this.handleUnauthorizedTwoFactorAuthentication();
    } else if (error instanceof MaintenanceApiServiceError) {
      this.handleMaintenance(error);
    }
  }

  private static handleUnauthorized(error: ApiServiceError): void {
    if (AccountHandler.isAuthenticated()) {
      AccountHandler.logout();
    }
    if (router.currentRoute.value.name !== "Login") {
      router.push({
        name: "Login",
      });
    }
    DsFlashNotifier.error(ErrorTranslator.translate(error));
  }

  private static handleUnauthorizedTwoFactorAuthentication(): void {
    const { isTwoFactorAuthenticationEnabled, enableTwoFactorAuthentication } =
      useTwoFactorAuthentication();
    if (!isTwoFactorAuthenticationEnabled()) {
      enableTwoFactorAuthentication();
      if (router.currentRoute.value.name !== "TwoFactorAuthentication") {
        router.push({
          name: "TwoFactorAuthentication",
        });
      }
    }
  }

  private static handleMaintenance(error: ApiServiceError): void {
    const { setOn } = useDsMaintenance();
    setOn(ErrorTranslator.translate(error));
  }
}
