export enum ApiErrorCode {
  InternalServerError = "internal_server_error",
  CreatorNeedsEscalatedPermission = "creator_needs_escalated_permission",
  Maintenance = "maintenance",
  Upgrading = "upgrading",
  InvalidInput = "invalid_input",
  CertificateNotFound = "certificate_not_found",
  CreatorLevelInsufficient = "creator_level_insufficient",
  CampaignMismatch = "job_creator_campaign_mismatch",
  CreatorLevelJobLimit = "creator_level_job_limitation_exception",
  CampaignCompletedApplicants = "campaign_creator_approval_not_found",
  CampaignCreatorApprovalExists = "campaign_creator_approval_exists",
  CampaignPaymentFailed = "payment_failed",
  CampaignDailyJobsLimitReached = "daily_jobs_limit_reached",
}
