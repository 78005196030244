import { ApiService } from "@/core/shared/services/ApiService";

import type {
  CampaignApply,
  CampaignExtended,
  HideCampaignResponse,
  Invitation,
  SetReminderResponse,
} from "@/modules/campaign/services/CampaignService.types";

/**
 * API Service for the campaign
 * @class CampaignService
 */
export default class CampaignService {
  /**
   * Get campaign details
   * @endpoint /creator/campaigns/{campaign_id}
   * @httpMethod GET
   * @throws {BadRequestApiServiceError}
   */
  static get(id: number): Promise<CampaignExtended> {
    return ApiService.get(`/creator/campaigns/${id}`);
  }

  /**
   * Apply campaign
   * @endpoint /creator/campaigns/{campaign_id}/apply
   * @httpMethod POST
   * @throws {BadRequestApiServiceError}
   */
  static apply(id: number): Promise<CampaignApply> {
    return ApiService.post(`/creator/campaigns/${id}/apply`);
  }

  /**
   * Reject invitation of brand
   * @endpoint /creator/campaigns/{campaign_id}/decline-invitation
   * @httpMethod POST
   * @throws {BadRequestApiServiceError}
   */
  static reject(campaignId: number): Promise<Invitation> {
    return ApiService.put(
      `/creator/campaigns/${campaignId}/decline-invitation`
    );
  }

  static hide(campaignId: number): Promise<HideCampaignResponse> {
    return ApiService.post(
      `/creator/creators/blacklisted-campaigns/${campaignId}/add`
    );
  }

  static unHide(campaignId: number): Promise<HideCampaignResponse> {
    return ApiService.delete(
      `/creator/creators/blacklisted-campaigns/${campaignId}/remove`
    );
  }

  /**
   * Revoke a campaign application
   * @endpoint /creator/campaigns/{campaign_id}/withdraw
   * @httpMethod POST
   * @throws {BadRequestApiServiceError}
   */
  static revoke(id: number): Promise<CampaignApply> {
    return ApiService.post(`/creator/campaigns/${id}/withdraw`);
  }

  static setReminder(
    campaignId: number,
    sendNotification: 0 | 1
  ): Promise<SetReminderResponse> {
    return ApiService.post(`/creator/campaigns/${campaignId}/set-reminder`, {
      send_notification: sendNotification,
    });
  }
}
